body {
    overflow-y: scroll;
}

.App {
    text-align: center;
    white-space: pre-line;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.path {
    stroke-dasharray: 1900;
    stroke-dashoffset: 1900;
    stroke-linecap: square;
    animation: dash 1.5s infinite alternate;
}

@keyframes dash {
    from {
        stroke-dashoffset: 1900;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(10%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.headerAnim {
    /* This section calls the slideInFromLeft animation we defined above */
    animation: 1.5s ease-out 0s 1 slideInFromBottom;
}

::selection {
    background-color: rgba(255, 122, 0, 0.5); /* WebKit/Blink Browsers */
    color: #333;
}

::-moz-selection {
    background-color: rgba(255, 122, 0, 0.5); /* Gecko Browsers */
    color: #333;
}

.snowflake {
    color: #fff;
    font-family: Arial, sans-serif;
    text-shadow: 0 0 2px #000;
    pointer-events: none;
}

@-webkit-keyframes snowflakes-fall {
    0% {
        top: -10%
    }
    100% {
        top: 100%
    }
}

@-webkit-keyframes snowflakes-shake {
    0%, 100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px)
    }
}

@keyframes snowflakes-fall {
    0% {
        top: -10%
    }
    100% {
        top: 100%
    }
}

@keyframes snowflakes-shake {
    0%, 100% {
        transform: translateX(0) rotate(0deg);
    }
    50% {
        transform: translateX(80px) rotate(60deg);
    }
}

.snowflake {
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    -webkit-animation:snowflakes-fall 10s linear infinite, snowflakes-shake 3s ease-in-out infinite;
    animation: snowflakes-fall 10s linear infinite, snowflakes-shake 3s ease-in-out infinite;
}

.snowflake:nth-of-type(0) {
    left: 1%;
    -webkit-animation-delay: 0s, 0s;
    animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
    left: 10%;
    -webkit-animation-delay: 1s, 1s;
    animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
    left: 20%;
    -webkit-animation-delay: 6s, .5s;
    animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
    left: 30%;
    -webkit-animation-delay: 4s, 2s;
    animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
    left: 40%;
    -webkit-animation-delay: 2s, 2s;
    animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
    left: 50%;
    -webkit-animation-delay: 8s, 3s;
    animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
    left: 60%;
    -webkit-animation-delay: 6s, 2s;
    animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
    left: 70%;
    -webkit-animation-delay: 2.5s, 1s;
    animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
    left: 80%;
    -webkit-animation-delay: 1s, 0s;
    animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
    left: 90%;
    -webkit-animation-delay: 3s, 1.5s;
    animation-delay: 3s, 1.5s
}

.snowflake:nth-of-type(10) {
    left: 25%;
    -webkit-animation-delay: 2s, 0s;
    animation-delay: 2s, 0s
}

.snowflake:nth-of-type(11) {
    left: 65%;
    -webkit-animation-delay: 4s, 2.5s;
    animation-delay: 4s, 2.5s
}
